<script>
import { headerMenu } from '@/CONF.js'

import { data_analysis } from '@/api/beijing28'

export default {
    name: 'Analysis',
    components: {
        Header: () => import('components/base/Header'),
    },
    data() {
        return {
            header: {
                mainTitle: getWord('analysis'),
                headerMenu,
            },
            analysisData: {
                history: null,
                today: null,
                drawings: null,
                date:null,
                count:null,
            }
        }
    },
    props: [

    ],
    methods: {

    },
    mounted() {
        var that = this;
        this.CustomScroll(this.$refs.summaryAnalysis.id);
        this.CustomScroll(this.$refs.drawingsAnalysis.id);
        data_analysis(JSON.parse(localStorage.getItem('game')).type).then(result => {
            const data = result.data.result
            that.analysisData.history = data.missing.missingSum
            that.analysisData.today = data.today.todaySum
            that.analysisData.drawings = data.issues
            that.analysisData.date = data.date
            that.analysisData.count = data.count
        })
    },
    computed: {

    },
    watch: {

    },
    inject: [

    ],
    updated() {

    }
};
</script>
<template>
    <transition name="slide">
        <div class="analysis" :class="_TEMPLATE">
            <template v-if="_TEMPLATE!=='template-2'">
                <Header :mainTitle="header.mainTitle" :headerMenu="header.headerMenu"></Header>
            </template>
            <h3>
                {{ getWord('tema_analysis') }}
                <span>{{ getWord('show_period') }}: <b>{{analysisData.count}}</b> {{analysisData.date}}</span>
            </h3>
            <div class="table" ref="summaryAnalysis" id="summary-analysis">
                <table>
                    <tr>
                        <th>{{ getWord('item_tema') }}</th>
                        <th v-for="item in 28">{{item-1}}</th>
                    </tr>
                    <tr>
                        <td>{{ getWord('two_days_ago') }}</td>
                        <template v-for="item in analysisData.history">
                            <td>{{item}}</td>
                        </template>
                    </tr>
                    <tr>
                        <td>{{ getWord('today2') }}</td>
                        <template v-for="item in analysisData.today">
                            <td>{{item}}</td>
                        </template>
                    </tr>
                </table>
            </div>
            <div class="table" ref="drawingsAnalysis" id="drawings-analysis">
                <table>
                    <tr>
                        <th>{{ getWord('number_of_period') }}</th>
                        <th>{{ getWord('value') }}</th>
                        <th>{{ getWord('small') }}</th>
                        <th>{{ getWord('da') }}</th>
                        <th>{{ getWord('dan') }}</th>
                        <th>{{ getWord('shuang') }}</th>
                        <th>{{ getWord('jida') }}</th>
                        <th>{{ getWord('jixiao') }}</th>
                        <th v-for="item in 28">{{item-1}}</th>
                    </tr>
                    <template v-for="(items,index) in analysisData.drawings">
                        <tr>
                            <td>{{items.issue}}</td>
                            <td>{{items.sum}}</td>
                            <td v-if="items.sum<13" class="even">{{ getWord('small') }}</td>
                            <td v-else></td>
                            <td v-if="items.sum>13" class="odd">{{ getWord('da') }}</td>
                            <td v-else></td>
                            <td v-if="items.sum%2!=0" class="odd">{{ getWord('dan') }}</td>
                            <td v-else></td>
                            <td v-if="items.sum%2==0" class="even">{{ getWord('shuang') }}</td>
                            <td v-else></td>
                            <td v-if="items.sum>=22" class="odd">{{ getWord('jida') }}</td>
                            <td v-else></td>
                            <td v-if="items.sum<=5" class="even">{{ getWord('jixiao') }}</td>
                            <td v-else></td>
                            <template v-for="(item,index) in 28">
                                <td v-if="index==items.sum">{{items.sum}}</td>
                                <td v-else-if="index!=items.sum"></td>
                            </template>
                        </tr>
                    </template>
                </table>
            </div>
        </div>
    </transition>
</template>
<style lang='scss' scoped>
@import "@@/assets/style/_variables";
.slide-enter-active {
    transition: all .3s ease;
}

.slide-leave-active {
    transition: all .3s ease;
}

.slide-enter {
    transform: translateX(100%);
}

.slide-enter-to {
    transform: translateX(0%);
}

.slide-leave {
    transform: translateX(0%);
}

.slide-leave-to {
    transform: translateX(-100%);
}

.analysis {
    display: flex;
    flex-direction: column;

    &.template-3 {

        h3 {
            font-size: .3rem;
            color: #333333;

            &:before {
                content:none;
            }

            span {
                color: #B0B0B0;

                b {
                    color: #B0B0B0;
                }
            }
        }

        .table {

            &:last-child {
                margin-top:.25rem;   
            }
        }

        table {

            th {
                background-color: #eeeeee;
                color: #B0B0B0;
            }
        }
    }

    &.template-2 {

        .table {
            padding-left: .25rem;
        }

        table {
            padding-left: .25rem;

            th,
            td {
                border-color: #f5f5f9;
            }

            th {
                color: $TEMPLATE2-theme-color;
                background-color: #f5f5f9;
            }

            td {
                &.odd,
                &.even {
                    background-color: #f5f5f9;
                }
            }
        }

        h3 {
            font-size: .36rem;
            font-weight: bold;

            &:before {
                content:none;
            }

            span {
                font-size: .28rem;
                font-weight: normal;
            }
        }
    }

    .table {
        overflow: auto;
        min-height: 1.8rem;

        &:last-child {
            margin-top: .5rem;
        }
    }

    table {
        border-collapse: collapse;

        tr {
            text-align: center;
        }

        th,
        td {
            font-size: .28rem;
            font-weight: 500;
            padding: 0.1rem 0;
            min-width: 1rem;
            border-right: 1px solid #D9D9D9;
            border-bottom: 1px solid #D9D9D9;

            &:first-child {
                padding-left: .1rem;
                padding-right: .1rem;
            }
        }

        th {
            background-color: #EAEAEA;
            color: #999999;
        }

        td {
            color: #333333;

            &.even {
                color:#3985E1;
            }

            &.odd {
                color:#E83535;
            }
        }
    }

    h3 {
        padding: .25rem;
        font-size: .32rem;
        font-weight: 500;
        color: #333333;

        span {
            float: right;
            padding-top: .06rem;
            font-size: .24rem;
            color: #999999;

            b {
                color: #3985E1;
                font-weight: 500;
                margin: 0 .2rem 0 .1rem;
            }
        }

        &:before {
            content: '';
            width: 3px;
            background-color: #0F417D;
            height: .32rem;
            display: inline-block;
            vertical-align: middle;
            margin-right: .125rem;
            border-radius: 1rem;
        }
    }
}
</style>